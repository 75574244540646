<template>
  <div id="admin-pickup-points">
    <div class="vx-row">
      <div class="vx-col w-full lg:w-1/2 mb-base">
        <h2>{{$route.name.includes('promo-codes') ? $t('Promo Codes') : $t('Automated Promo Codes')}}</h2>
      </div>
      <div v-if="$store.state.AppActiveUser.userRole === 'growth-hacker' && $route.name.includes('promo-codes')" class="vx-col w-full lg:w-1/2 items-end">
        <div class="flex justify-between">
          <span class="font-semibold"></span>
          <span class="font-medium text-primary cursor-pointer">
            <vs-button
              @click="newPromoCode()"
              color="primary"
              icon-pack="feather"
              icon="icon-plus"
              size="large"
            >
            </vs-button>
          </span>
        </div>
      </div>
    </div>
    <head-component :noActions="true" :statusFilters="statusFilters" :selected="selected" @changeFilter="changeFilter" @filterValue="filterValue = $event"></head-component>
    <shipblu-table
     :sst="true"
      multiple
      v-model="selected"
      :max-items="maximumItems"
      pagination
      search
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      :data="data"
      :tableLoader="promoLoading"
    >
      
      <template slot="thead">
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th>{{$t('Code')}}</shipblu-th>
        <shipblu-th>{{$t('Scope')}}</shipblu-th>
        <shipblu-th>{{$t('Service Types')}}</shipblu-th>
        <shipblu-th>{{$t('Auto Apply')}}</shipblu-th>
        <shipblu-th>{{$t('Start Date')}}</shipblu-th>
        <shipblu-th>{{$t('Expiry Date')}}</shipblu-th>
        <shipblu-th>{{$t('Units')}}</shipblu-th>
        <shipblu-th>{{$t('Value')}}</shipblu-th>
        <shipblu-th>{{$t('No. Merchants')}}</shipblu-th>
        <shipblu-th>{{$t('Total Usage Count')}}</shipblu-th>
        <shipblu-th>{{$t('Total Usage Value')}}</shipblu-th>
        <shipblu-th v-if="$store.state.AppActiveUser.userRole === 'growth-hacker' && $route.name.includes('promo-codes')">{{$t('Actions')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td :data="data[indextr].status">
            <div class="m-auto flex items-center justify-center font-semibold">
              <span class="capitalize" :class="new Date(data[indextr].limits.expiry_date) >= new Date() ? 'text-green' : 'text-red'">
                {{ new Date(data[indextr].limits.expiry_date) >= new Date() ? 'Active' : 'Expired' }}
              </span>
            </div>
          </shipblu-td>

          <shipblu-td :data="data[indextr].code">
              {{ data[indextr].code }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].scope">
            {{ data[indextr].scope }}
          </shipblu-td>
          
          <shipblu-td :data="data[indextr].serviceTypes">
            <p v-for="item in data[indextr].qualifier.service_types" :key="item.index">{{ item }}</p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].autoApply">
            {{ data[indextr].auto_apply }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].startDate">
            {{ data[indextr].limits.start_date }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].expiryDate">
            {{ data[indextr].limits.expiry_date }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].units">
            {{ data[indextr].unit }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].value">
            {{ Number(data[indextr].value).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
          </shipblu-td>
          
          <shipblu-td :data="data[indextr].value">
            {{ data[indextr].total_usage_merchants_count }} / {{ data[indextr].qualifier.merchants.length }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].value">
            {{ data[indextr].total_usage_count }} / {{ data[indextr].limits.total_usage_count }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].value">
            {{ data[indextr].total_usage_value }} / {{ data[indextr].limits.total_cash_value }}
          </shipblu-td>

          <shipblu-td v-if="$store.state.AppActiveUser.userRole === 'growth-hacker' && $route.name.includes('promo-codes')">
            <vs-dropdown
              vs-trigger-click
              class="dd-actions cursor-pointer"
              @click="selected = []"
            >
              <vs-button
                type="border"
                size="small"
                icon-pack="feather"
                icon="icon-more-horizontal"
                class="mr-2"
              >
              </vs-button>
              <vs-dropdown-menu style="width: 190px">
                <vs-dropdown-item
                  @click="openPromoAndExceptionModal(data[indextr], 'Edit')"
                >
                  <span class="flex items-center">
                    <feather-icon
                      icon="EditIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>{{$t('Edit')}}</span>
                  </span>
                </vs-dropdown-item>
                <vs-dropdown-item
                  v-if="$route.name === 'growth-hacker-promo-codes'"
                  @click="openPromoAndExceptionModal(data[indextr], 'Duplicate')"
                >
                  <span class="flex items-center">
                    <feather-icon
                      icon="CopyIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>{{$t('Duplicate')}}</span>
                  </span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
    <add-promo-code :type="type" :promoAndExceptionModal="promoCodeModal" @promoAndExceptionModal="promoCodeModal = $event" :data="promoCode" @loadData="loadPromoCodes"></add-promo-code>
  </div>
</template>

<script>
import AddPromoCode from './components/AddPromoCode.vue'
import { sendRequest }  from '../../http/axios/requestHelper.js'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'
import HeadComponent from '../merchant/components/Header.vue'
import common from '../../assets/utils/common'

export default {
  data () {
    return {
      data: [],
      statusFilters: [
        {
          name: 'All',
          value: 'all'
        },
        {
          name: 'Active',
          value: 'active'
        },
        {
          name: 'Expired',
          value: 'expired'
        }
      ],
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      filters: [],
      filterValue: '',
      searchVal: '',
      promoLoading:false,
      type: '',
      promoCodes: [],
      promoCode: {
        value: 0,
        limits: {
          expiry_date: '',
          per_merchant_cash_value: '',
          per_merchant_usage_count: '',
          start_date: '',
          total_cash_value: '',
          total_usage_count: ''
        },
        qualifier: {
          merchants: [],
          package_sizes: [],
          service_types: [],
          subscriptions: []
        },
        threshold: {
          count: 0,
          time_window: ''
        }
      },
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      promoCodeModal: false,
      searchInProgress: false,
      searchedValue: ' '
    }
  },
  watch: {
    '$route.name' () {
      this.loadPromoCodes()
    },
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadPromoCodes()
    }
  },
  methods: {
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadCodesSearch)
    },
    loadCodesSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadPromoCodes()
    },
    changeFilter () {
      this.filters = ''
      this.filters = this.filterValue
      if (this.filterValue === 'all') {
        this.filters = ''
      }
      this.currentPage = 1
      this.$router.push({
        query: {
          page: 1,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadPromoCodes()
    },
    loadPromoCodes () {
      this.searchInProgress = true
      this.promoLoading = true
      sendRequest(true, false, this, `api/v1/discounts/promo-codes/?offset=${this.offset}&limit=${this.maximumItems}&search=${this.searchVal}${this.$route.name.includes('automated-codes') ? '&special_code=true' : '&special_code=false'}&expiry_status=${this.filters}`, 'get', null, true,
        (response) => {
          this.data = response.data.results
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
          this.promoLoading = false
        }
      )
      this.searchInProgress = false
    },
    newPromoCode () {
      this.promoCodeModal = true
      this.type = 'Add'
      this.promoCode = {
        value: 0,
        limits: {
          expiry_date: '',
          per_merchant_cash_value: '',
          per_merchant_usage_count: '',
          start_date: '',
          total_cash_value: '',
          total_usage_count: ''
        },
        qualifier: {
          merchants: [],
          package_sizes: [],
          service_types: [],
          subscriptions: []
        },
        threshold: {
          count: 0,
          time_window: ''
        }
      }
    },
    openPromoAndExceptionModal (data, type) {
      sendRequest(false, false, this, `${this.$route.name === 'growth-hacker-exception-discount' ? 'api/v1/discounts/exception-codes/' :  'api/v1/discounts/promo-codes/'}${data.id}/`, 'get', null, true,
        (response) => {
          this.promoCode = response.data
          this.promoCode = {
            ...this.promoCode,
            unit: this.promoCode.unit === 'absolute' ? 'EGP' : 'Percentage',
            threshold: {
              count: this.promoCode.threshold ? this.promoCode.threshold.count : '',
              time_window: this.promoCode.threshold ? this.promoCode.threshold.span === 0  ? `This ${this.promoCode.threshold.time_window}`  : `Last ${this.promoCode.threshold.time_window}` : ''
            }
          }
          this.promoCodeModal = true
          this.type = type
        }
      )
    }
  },
  components: {
    AddPromoCode,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination,
    HeadComponent

  },
  created () {
    this.filters = this.$route.query.filter ? typeof (this.$route.query.filter) === 'string' ? Array(this.$route.query.filter) : this.$route.query.filter : ''
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadPromoCodes()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
</style>

<style lang="scss">
.con-pagination-table{
  display: none;
}
</style>